import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import '../styles/index.css';

function Index() {
  return (
    <>
      <Helmet>
        <title>Little Martian</title>
        {/* <meta http-equiv="Refresh" content={`0; URL=${first.node.fields.slug}`}></meta> */}
        <meta http-equiv="Refresh" content="0; URL=/demo"></meta>
      </Helmet>
      <Header />
      <main></main>
      <Footer />
    </>
  );
}

export default Index;
